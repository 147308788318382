import BtnPrimary from "../../basic/buttons/BtnPrimary";
import BorderDash from "../../basic/deco/BorderDash";
import ImgContact from "../../../assets/home_contact.jpg"
import SubTitle from "../../basic/title/SubTitle";
import HeaderTitle from "../../basic/title/HeaderTitle";
import CardSecDescContainer from "../../basic/cards/CardSecDescContainer";
import CardSecImgContainer from "../../basic/cards/CardSecImgContainer";
import ImgContainer from "../../basic/image/ImgContainer";

const SecContact = () => {
    return ( 
        <section id="Contact" className='bg-PRIMARYGRAY'>
            <div className="px-4 pt-14 pb-6 bg-Line3dBg bg-cover">
                
                <SectionContainer />

            </div>
        </section>
     );
}
 
const SectionContainer = () => {
    return (
        <div className="p-2 md:p-4 xl:p-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl">
            <div className="flex flex-col items-center justify-between w-full sm:flex-row">

                <CardSecDescContainer isAniHoriLeft={false}>
                    <div className="max-w-xl mb-6">
                        <SubTitle>
                            Contact
                        </SubTitle>
            
                        <HeaderTitle>
                            Reach us now
                        </HeaderTitle>
                        
                        <BorderDash />

                        <p className="my-4 text-black text-base md:text-lg"> 
                            For more detail information and to learn more, reach us out now by clicking the button below.
                        </p>

                        <BtnPrimary btnName={'Contact'} btnLink={'/contact'} isArrow={true} />
                    </div>
                </CardSecDescContainer>

                <CardSecImgContainer isLast={false}>
                    <ImgContainer ImgSrc={ImgContact} ImgAlt="contact-img" isRound={true}/>
                </CardSecImgContainer>
            </div>
        </div>
    )
}

export default SecContact;