import { NavLink as NaviLinkR  } from 'react-router-dom';
type BtnContentType = {
    btnName: string;
    btnLink: string;
    isArrow: boolean;
};

const BtnPrimary = ( {btnName, btnLink, isArrow = true} : BtnContentType ) => {
    return (
        <button>
            <NaviLinkR 
                to={btnLink} 
                className="inline-flex items-center justify-center px-5 py-2 
                text-base font-bold text-center 
                border-2 border-primary rounded-lg text-primary
                bg-white hover:bg-blue-800 hover:text-white 
                focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900"
            >
                {btnName}
                {isArrow && 
                <svg className="w-5 h-5 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                </svg>
                }
            </NaviLinkR>
            
        </button>
    );
}
 
export default BtnPrimary;