import { Routes, Route, useLocation } from 'react-router-dom';
import NotFound from '../../../pages/404/404';
import About from '../../../pages/about/aboutPage';
import Contact from '../../../pages/contact/contactPage';
import HomePage from '../../../pages/home/homepage';
import Services from '../../../pages/services/servicesPage';
import { Urls } from '../urls/urls';
import { AnimatePresence } from 'framer-motion'

type LanguageType = {
    isEnglish: boolean;
    LangToggle: any; 
};

const RouteList = ({isEnglish, LangToggle} : LanguageType) => {
    const location = useLocation();

    return ( 
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>
                <Route path={Urls.Home} element={<HomePage isEnglish={isEnglish}/>} />
                <Route path={Urls.About} element={<About isEnglish={isEnglish}/>} />
                <Route path={Urls.Services} element={<Services />} />
                <Route path={Urls.Contact} element={<Contact isEnglish={isEnglish} LangToggle={LangToggle} />} />
                <Route path={Urls.Error} element={<NotFound />} />
            </Routes>
        </AnimatePresence> 
     );
}
 
export default RouteList;