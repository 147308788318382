import DecoLoading from "../deco/DecoLoading";
import {Suspense} from 'react';

type ImgType = {
    ImgSrc: string;
    ImgAlt: string;
    isRound: boolean;
};


const ImgContainer = ( {ImgSrc, ImgAlt, isRound} : ImgType) => {
        
    return ( 
        <div
            className="w-full"
        >
            <Suspense fallback={<DecoLoading />}>
                <img
                    alt={ImgAlt}
                    src={ImgSrc}
                    className={isRound ? "rounded-lg shadow-xl" : ""}
                />
            </Suspense>
        </div>
     );
}
 
export default ImgContainer;