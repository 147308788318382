type TypeHeaderTitle = {
    children: React.ReactNode;
};

const HeaderTitle = ({children} : TypeHeaderTitle) => {
    return ( 
        <h2 className="font-sans text-3xl font-bold tracking-tight text-black sm:text-4xl sm:leading-none max-w-lg mb-0">
            {children}
        </h2>
     );
}
 
export default HeaderTitle;