import { NavLink as NaviLinkR  } from 'react-router-dom';

type BtnContentType = {
    btnName: string;
    btnLink: string;
    isArrow: boolean;
};

const BtnTertier = ( {btnName, btnLink, isArrow = true} : BtnContentType) => {
    return ( 
        <button>
            <NaviLinkR 
                to={btnLink}
                className='px-6 py-2 rounded-lg
                text-xl font-bold tracking-wide cursor-pointer
                flex justify-start items-start h-full
                hover:bg-white hover:text-primary
            '>
                {btnName}
                {isArrow && <span className='flex justify-start items-start'>
                    <svg className="w-5 h-5 ml-2 mr-1 my-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                    </svg>
                </span>}
            </NaviLinkR>
        </button>
     );
}
 
export default BtnTertier;