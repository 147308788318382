
import { motion } from 'framer-motion';
import React from 'react';
import SecHero from '../../components/basic/hero/SecHero';
import SecAboutUs from '../../components/specific/Home/SecAbout';
import SecContact from '../../components/specific/Home/SecContact';
import SecService from '../../components/specific/Home/SecService';

const HomePage = ({isEnglish} : {isEnglish:boolean}) => {
    return ( 
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            // exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
        >
            <SecHero />

            <SecService />

            <SecAboutUs isEnglish={isEnglish}/>

            <SecContact />
        </motion.div>
     );
}
 
export default HomePage;