import { useState, useEffect } from 'react';
import HeaderFooter from './components/basic/layouts/HeaderFooter';
import RouteList from './components/basic/layouts/RouteList';


const App = () => {

  const catchPhrase = 'Go Global by Bridging Businesses';

  // Configure the title of the page
  useEffect(() => {
    document.title = catchPhrase + ' | KakeGlobal';
  }, []);

  // Languange changing fuctions {isEnglish, LangToggle}
  // Logic: true is english, false is japanese
  const [isEnglish, setIsEnglish] = useState<boolean>(true);
  const LangToggle = () => setIsEnglish(!isEnglish);
  
  return (
    <>
      <HeaderFooter isEnglish={isEnglish} LangToggle={LangToggle}>

        <RouteList isEnglish={isEnglish} LangToggle={LangToggle}/>

      </HeaderFooter>
    </>
  );  
}

export default App;
