type TypeSubTitle = {
    children: React.ReactNode;
};

const SubTitle = ({children} : TypeSubTitle) => {
    return ( 
        <h1 className="mb-6 text-sm font-semibold leading-none text-left text-indigo-600 uppercase">
            {children}
        </h1>
     );
}
 
export default SubTitle;