import React from 'react';
import Footer from '../../specific/footer/footer';
import Header from '../../specific/header/header';

type AppProps = {
    children: React.ReactNode;
    isEnglish: boolean;
    LangToggle: any;
};

const HeaderFooter = ( {children, isEnglish, LangToggle} : AppProps) => {
    return ( 
        <>
            <Header isEnglish={isEnglish} LangToggle={LangToggle}/>

            <div>
                {children}
            </div>

            <Footer isEnglish={isEnglish} LangToggle={LangToggle}/>
        </>
     );
}
 
export default HeaderFooter;