// import HeroBG from '../../../assets/home_hero.jpg'; 
import BtnPrimary from "../buttons/BtnPrimary";

const SecHero = () => {

    return ( 
        <section id='hero' 
        className="bg-primary
            min-h-[calc(100vh-180px)]
        ">
            <div className="relative
                flex flex-row justify-center items-center
            ">

                <div className="bg-PRIMARYGRAY bg-hero-pattern bg-cover
                    absolute top-[calc(8vh)]
                    z-[2] 
                    min-h-[calc(75vh-80px)] 
                    w-[98vw]
                    rounded-md
                    flex flex-row justify-center items-center
                ">
                   <div className="
                        absolute top-[20vh]
                        z-[3]
                        w-[89vw] sm:w-[80vw] xl:w-[45vw] 2xl:w-[50vw]
                    ">
                        <HeadLines />
                    </div> 
                </div>
            </div>

            <div className="h-[calc(50vh-80px)] bg-PRIMARY" />
            <div className="h-[calc(50vh-100px)] bg-PRIMARYGRAY" />

        </section>
     );
}

const HeadLines = () => {
    return (
        <div className="
            flex flex-col justify-center items-center 
            p-2 sm:p-4
            backdrop-opacity-10 backdrop-invert bg-white/30
            rounded-md 
        ">
            <CatchPhraseHead />

            <div className="w-[85%] sm:w-[60%] md:w-[45%] 
                flex flex-row justify-evenly items-center
            ">
                <BtnPrimary btnName={'Explore'} btnLink={'/services'} isArrow/>
                <BtnPrimary btnName={'Contact'} btnLink={'/contact'} isArrow/>
            </div>
        </div>
    )
}

const CatchPhraseHead = () => {
    return (
        <h1 className="
            px-2 font-extrabold leading-none tracking-tight text-gray-900 
            mb-6 sm:mb-10
        ">
            <mark className="px-2 text-white rounded bg-primary inline-block
            text-4xl xl:text-5xl 2xl:text-6xl
            ">
                Go Global 
            </mark>
            <span className="px-2 italic font-light
            text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl
            ">
                by 
            </span> 
            <span className="pr-2 text-gray-900 rounded inline-block
            text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl
            "> 
                Bridging Businesses
            </span> 
        </h1>
    )
}

 
export default SecHero;