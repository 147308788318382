import BorderDash from "../../components/basic/deco/BorderDash";
import ImgAsiaMap from "../../assets/AsiaMap2.svg"
import BizCard from "../../assets/BizCard.png"
import { motion } from "framer-motion";
import { CONTACT_PAGE_CONTENT as contact } from '../../data/WebContent';
import ImgContainer from "../../components/basic/image/ImgContainer";
import CardSecImgContainer from "../../components/basic/cards/CardSecImgContainer";
import { useEffect } from "react";


type LanguageType = {
    isEnglish: boolean;
    LangToggle: any; 
};

const Contact = ({isEnglish, LangToggle} : LanguageType) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    
    return ( 
        <motion.section 
            className='bg-PRIMARYGRAY'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            // exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
        >
            <div className="px-4 pt-14 pb-6 bg-Line3dBg bg-cover">

            <ContactBizCard isEnglish={isEnglish} LangToggle={LangToggle} />

            <SectionContainer isEnglish={isEnglish} LangToggle={LangToggle} />

            </div>
        </motion.section>
            
     );
}


const ContactBizCard = ( {isEnglish, LangToggle} : LanguageType) => {
    return (
        <div className="p-2 md:p-4 xl:p-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl">
            <div className="flex flex-col items-center justify-between w-full sm:flex-row">

                <div className="flex flex-col justify-center mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pl-16">
                    <div className="max-w-xl mb-6">
                        <h1 className="mb-6 text-sm font-semibold leading-none text-left text-indigo-600 uppercase">
                            Contact
                        </h1>
            
                        <h2 className="font-sans text-3xl font-bold tracking-tight text-black sm:text-4xl sm:leading-none max-w-lg">
                            {isEnglish ? contact[0].header : contact[0].header_jp}
                        </h2>

                        <BorderDash />

                        <p className="my-4 text-black text-base md:text-lg">
                            {isEnglish ? contact[0].description : contact[0].description_jp}
                        </p>

                        <ContactDetails isEnglish={isEnglish} LangToggle={LangToggle}/>

                    </div>
                </div>

                <CardSecImgContainer isLast={false}>
                    <ImgContainer ImgSrc={BizCard} ImgAlt="contact-page" isRound={true}/>
                </CardSecImgContainer>
            </div>
        </div>
    )
}

const ContactDetails = ( {isEnglish, LangToggle} : LanguageType ) => {
    return (
        <>
        <p className="flex items-center justify-center md:justify-start mb-4">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="home"
                className="w-4 mr-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                <path fill="currentColor"
                d="M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z">
                </path>
            </svg>
            〒108-0074<br/>Tokyo Minato City<br/>Takanawa 4-23-6<br/>High Home Takanawa 708
        </p>
        <p className="flex items-center justify-center md:justify-start mb-4">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="envelope"
                className="w-4 mr-4" role="img" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512">
                <path fill="currentColor"
                d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z">
                </path>
            </svg>
            {isEnglish ? contact[0].details_2 : contact[0].details_2_jp}
        </p>
        {/* <p className="flex items-center justify-center md:justify-start mb-4">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone"
                className="w-4 mr-4" role="img" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512">
                <path fill="currentColor"
                d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z">
                </path>
            </svg>
            + 01 234 567 88
        </p> */}
        </>
    );
}

const SectionContainer = ( {isEnglish} : LanguageType) => {
    return (
        <div className="mt-6 md:mt-2 p-2 md:p-4 xl:p-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl">
            <div className="flex flex-col items-center justify-between w-full sm:flex-row">

                <div className="flex flex-col justify-center mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pl-16">
                    <div className="max-w-xl mb-6">
                        <h1 className="mb-6 text-sm font-semibold leading-none text-left text-indigo-600 uppercase">
                            Contact
                        </h1>
            
                        <h2 className="font-sans text-3xl font-bold tracking-tight text-black sm:text-4xl sm:leading-none max-w-lg">
                            {isEnglish ? contact[1].header : contact[1].header_jp} &#128340;
                        </h2>

                        <BorderDash />

                        <p className="my-4 text-black text-base md:text-lg"> 
                            <span>
                                {isEnglish ? contact[1].description : contact[1].description_jp}
                            </span>
                            <br/>
                            {isEnglish ? contact[1].details_1 : contact[1].details_1_jp}
                        </p>

                    </div>
                </div>

                <CardSecImgContainer isLast={true}>
                    <img alt="Asia-business-map" width={550} height={550} src={ImgAsiaMap} />
                </CardSecImgContainer>
            </div>
        </div>
    )
}
 
export default Contact;