
/* ============================= 
    General
 ============================= */
 export const GeneralInfo = {
    companyShortName: 'Kake Global Inc.',
    companyShortName_jp: 'カケ　グローバル',
    companyName: 'Kake Global Inc.',
    companyName_jp: 'カケ　グローバル 合同会社',
    companyPhrase: 'Connecting Japan to the world through commerce and technology',
    companyPhrase_jp: '商取引と技術で日本と世界をつなぐ',
    website: 'http://kakeglobal.com'

};

export const Warna = {
    primary: '#30449A',
    secondary: '#4E80ED',
    tertiary: '#51B0E3',
    PRIMARYBLACK: '#1A1A1A',
    PRIMARYWHITE: '#ffffff',
    PRIMARYGRAY: '#f4f4f4',
    PRIMARYRED: '#FF1E25',
    PRIMARYGOLD: '#FFD700',
};

/* ============================= 
    Nav - Sections
 ============================= */
 export const NAVBAR_LIST = [
{
    id: 0,
    to_home : 'Services',
    to : '/Services',
    title : 'Services'
},
{
    id: 1,
    to_home : 'About',
    to : '/About',
    title : 'About'
},
{
    id: 2,
    to_home : 'Contact',
    to : '/Contact',
    title : 'Contact'
},

]

/* ============================= 
   SERVICE SECTIONS ==  
 ============================= */
export const SERVICES_LIST = [
{
    id: 0,
    title : 'Import & Export',
    title_jp : '貿易・卸売事業',
    description : 'Ensure continuous supply of your products with integrated logistics solutions and proven product expertise.',
    description_jp : '統合されたロジスティクスソリューションと実績のある製品の専門知識により、製品の継続的な供給を確保します。',
    icon: '',
    img_alt: 'ImportExport_Container',
    img_url: 'public/Images/LandingPage/home_service_import.jpg'
},
{
    id: 1,
    title : 'IT Consultancy',
    title_jp : 'ITコンサルタント',
    description : 'Maximize your cost-performance ratio with our IT and Engineering services.',
    description_jp : 'ITおよびエンジニアリングサービスでコストパフォーマンスを最大化',
    icon: '',
    img_alt: 'Consultation',
    img_url: 'public/Images/LandingPage/home_service_consultation.jpg'
},
]

export const SERVICES_PAGE_ITEMS = [
    {
        id: 0,
        icon: '&#128747;',
        Title: 'Import & Export',
        Desc: 'Ensure continuous supply of your products with integrated logistics solutions and proven product expertise.'
    },
    {
        id: 1,
        icon: '&#127759;',
        Title: 'Bring Local Food to Global',
        Desc: 'We would love to bring the value of your product from local level to Global level. Feeling worried? contacting us and we will help your product.'
    },
    {
        id: 2,
        icon: '&#128421;',
        Title: 'IT Consultancy',
        Desc: 'Today, you need to ensure the consistent delivery of high-quality IT products and services for your enterprise workflow & your customers. Our IT solutions consulting will help your strike the balance between your objectives, ensuring you to achieve your goals'
    },
    {
        id: 3,
        icon: '&#128126;',
        Title: 'Front End Development',
        Desc: 'We offer good values for small and medium business to grow using our Front End service with a deep focus on HTML, CSS. The result of our work is a responsive, accessible, and performant websites.'
    },
]

// ==========================================
// == ABOUT ==  
// ==========================================
//    == HOME - About ==  
export const AboutSec_content = {
    title: 'Bridging Business',
    title_jp: '会社概要',
    catchphrase : '"Connecting Japan to the world through commerce and technology"',
    catchphrase_jp : '"商取引と技術で日本と世界をつなぐ"',
    description : 'Kake Global Inc. treats "trust" and "respect" as our core values and inspiration. The word "kake" exhibits us as an international trading company started in Japan to bridge between local businesses with international audience. Combining both "Kake" word in Japanese and "Global" shows our multinational culture and mindset.',
    description_jp : 'Kake Global 合同会社は、「信頼」と「尊敬」を私たちのコアバリューとインスピレーションとして扱っています。 「カケ」という言葉は、地元企業と国際的な聴衆との間をつなぐ国際商社としての私たちを表しています。日本語の「カケ」とドイツ語の「ブリュッケ」の両方を組み合わせることで、私たちの多国籍文化と考え方がわかります。',
    button_name: 'Find out more',
    button_name_jp: '詳しくはこちら',
    buttonLink: '/about',
    img_alt: 'About Picture',
    img_url: 'public/Images/LandingPage/home_about.jpg',
};

//    == PAGE - About == 
export const CompanyInfoTable = [
    {
        id: 0,
        label: 'Company Name',
        label_jp: '社名',
        content: ['Kake Global Incorporation'],
        content_jp: ['Kake Global 合同会社'],
    },
    {
        id: 1,
        label: 'Director',
        label_jp: 'ディレクター',
        content: [
            'Wan Muhamad Khairi Bin Wan Kadir', 
        ],
        content_jp: [
            'ワン　ムハマド　カイリ　ワン　カディル',
        ]
    },
    {
        id: 2,
        label: 'Capital',
        label_jp: '資本金',
        content: ['5,000,000 Yen'],
        content_jp: ['5,000,000円'],
    },
    {
        id: 3,
        label: 'Business',
        label_jp: '事業内容',
        content: [
            'Trading & Wholesales Business',
            'Retail Product Import',
            'Consultation',
        ],
        content_jp: [
            '貿易・卸売事業',
            'ITコンサルタント・ITサービス',
        ],
    },
    {
        id: 4,
        label: 'URL',
        label_jp: 'URL',
        content: ['http://kakeglobal.com'],
        content_jp:[ 'http://kakeglobal.com'],
    },
];

//    == PAGE - About == 
export const AboutPAGE_content = {
    title_top: 'Corporate Profile',
    title_top_jp: '会社概要',
    title_2: 'Corporate Philosophy',
    title_2_jp: '経営理念',
    mainQuote: 'Kake Global Inc. treats "trust" and "respect" as our core values and inspiration. The word "kake" exhibits us as an international trading company started in Japan to bridge between local businesses with international audience. Combining both "Kake" word in Japanese and "Global" shows our multinational culture and mindset.',
    mainQuote_jp: 'Kake Global 合同会社は、「信頼」と「尊敬」を私たちのコアバリューとインスピレーションとして扱っています。 「カケ」という言葉は、地元企業と国際的な聴衆との間をつなぐ国際商社としての私たちを表しています。日本語の「カケ」と「Global」の両方を組み合わせることで、私たちの多国籍文化と考え方がわかります。',
    missionTitle: 'Mission',
    missionTitle_jp: 'ミッション',
    missionContent: 'Grow together by bringing local businesses to the global stage',
    missionContent_jp: 'ローカルビジネスをグローバルステージに持ち込むことで一緒に成長する',
    visionTitle: 'Vision',
    visionTitle_jp: 'ヴィジョン',
    visionContent: 'Go Global by Bridging Businesses',
    visionContent_jp: 'ビジネスの橋渡しとグローバル化',
    
};

// ==========================================
// == CONTACT SECTIONS ==  
// ==========================================

export const ContactSec_content = {
    title: 'Do you have any inquiry? Contact us now',
    title_jp: 'ご相談・お見積もりはお気軽にお問い合わせください',
    addressLoc: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3241.7004771153393!2d139.72729791487268!3d35.659750538757926!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188b7769bcd655%3A0x4659072dbf2390e2!2sRoppongi%20Hills%20Cross%20Point%2C%206-ch%C5%8Dme-3-1%20Roppongi%2C%20Minato%20City%2C%20Tokyo%20106-0032!5e0!3m2!1sen!2sjp!4v1645265136246!5m2!1sen!2sjpessLoc',
    contactAddress : '〒108-0074 Tokyo Minato City Takanawa 4-23-6 High Home Takanawa 708',
    contactEmail : 'contact@kakeglobal.com',
    iconSize: 40,
    buttonLink: '/contact',
    button_name: 'Find out more',
    button_name_jp: '詳しくはこちら',
};

export const ContactPage_content = {
    title_top: 'Reach Us',
    title_top_jp: 'お問い合せ',
    DescSubtitle: 'Customer Service',
    DescSubtitle_jp: 'お客様窓口',
    title: 'Do you have any inquiry? Contact us now',
    title_jp: 'ご相談・お見積もりはお気軽にお問い合わせください',
    contactAddress : '〒108-0074 Tokyo Minato City Takanawa 4-23-6 High Home Takanawa 708',
    contactAddress_jp : '〒108-0074 東京都港区高輪4丁目23番6号 ハイホーム高輪 708',
    contactEmail : 'contact@kakeglobal.com',
    iconSize: 38,
    button_name: 'Find out more',
    button_name_jp: '詳しくはこちら',
    workingTime: 'Office hour: 9AM ～ 5PM (Excluding Weekends and Public Holidays)',
    workingTime_jp: '営業時間 午前9時～午後5時（土・日・祝祭日、年末年始を除く）',
};
