type TypeWordBGSquare = {
    children: React.ReactNode;
};

const WordBGSquare = ({children} : TypeWordBGSquare) => {
    return ( 
        <mark className="mx-1 p-1 font-semibold italic text-white rounded bg-primary">
            {children}
        </mark>
     );
}
 
export default WordBGSquare;