/* ============================= 
    General
 ============================= */
 export const GeneralInfo = {
    companyShortName: 'KKG Inc.',
    companyShortName_jp: 'KKG Inc.',
    companyName: 'Kake Global Inc.',
    companyName_jp: 'カケ　グローバル 合同会社',
    companyPhrase: 'Go Global by Bridging Businesses',
    companyPhrase_jp: 'ゴーグロバル　世界をつなぐ',
    website: 'http://kakeglobal.com'

};

export const Warna = {
    primary: '#30449A',
    secondary: '#4E80ED',
    tertiary: '#51B0E3',
    PRIMARYBLACK: '#1A1A1A',
    PRIMARYWHITE: '#ffffff',
    PRIMARYGRAY: '#f4f4f4',
    PRIMARYRED: '#FF1E25',
    PRIMARYGOLD: '#FFD700',
};

/* ============================= 
    Nav - Sections
 ============================= */
 export const NAVBAR_LIST = [
{
    id: 0,
    to_home : 'Services',
    to_home_jp : 'サービス',
    to : '/Services',
    title : 'Services'
},
{
    id: 1,
    to_home : 'About',
    to_home_jp : '会社紹介',
    to : '/About',
    title : 'About'
},
{
    id: 2,
    to_home : 'Contact',
    to_home_jp : 'お問い合わせ',
    to : '/Contact',
    title : 'Contact'
},

]

/* ============================= 
   HOME SECTIONS ==  
 ============================= */
export const HOME_SERVICES_LIST = [
{
    id: 0,
    title : 'Import & Export',
    title_jp : '貿易・卸売事業',
    description : 'We offer our expertise in import export especially in F&B. Ensure continuous supply of your products with integrated logistics solutions and proven product expertise.',
    description_jp : '統合されたロジスティクスソリューションと実績のある製品の専門知識により、製品の継続的な供給を確保します。',
    img_alt: 'services-trading',
    img_url: 'public/Images/LandingPage/home_service_import.jpg'
},
{
    id: 1,
    title : 'Consultancy',
    title_jp : 'コンサルタント',
    description : 'We also offer our expertise in consultation in F&B area and also Technolgy area especially in Automotive industry, ADAS. Maximize your cost-performance ratio with our IT and Engineering services.',
    description_jp : 'ITおよびエンジニアリングサービスでコストパフォーマンスを最大化',
    img_alt: 'services-consultation',
    img_url: 'public/Images/LandingPage/home_service_consultation.jpg'
},
]

export const WHY_CHOOSE_US_LIST = [
{
    id: 0,
    title : 'Why Choose Us',
    title_jp : '貿易・卸売事業',
    description : 'Our expertise and passion for flawless execution in every aspects of the service chain that makes Kake Global stands out as your preferred partner.',
    description_jp : '統合されたロジスティクスソリューションと実績のある製品の専門知識により、製品の継続的な供給を確保します。',
},
]

//    == HOME - About ==  
export const HOME_ABOUT_CONTENT = {
    title: 'Kake Global Inc.',
    title_jp: 'カケ　グローバル 合同会社',
    catchphrase : 'Go Global by Bridging Businesses',
    catchphrase_jp : 'ゴーグロバル　世界をつなぐ',
    description : 'Kake Global Inc. treats "trust" and "respect" as our core values and inspiration. The word "kake" exhibits us as an international trading company started in Japan to bridge between local businesses with international audience. Combining both "Kake" word in Japanese and "Global" shows our multinational culture and mindset.',
    description_jp : 'Kake Global 合同会社は、「信頼」と「尊敬」を私たちのコアバリューとインスピレーションとして扱っています。 「カケ」という言葉は、地元企業と国際的な聴衆との間をつなぐ国際商社としての私たちを表しています。日本語の「カケ」と英語の「グロバル」、そのものの意味で、両方を組み合わせることで、私たちの多国籍文化と国際的な考え方がわかります。',
    button_name: 'More',
    button_name_jp: '詳しくはこちら',
    buttonLink: '/about',
    img_alt: 'about-us-building',
    img_url: 'public/Images/LandingPage/home_about.jpg',
};

//    == HOME - Contact ==  
export const HOME_CONTACT_CONTENT = {
    title: 'For more detail information and to learn more, reach us out now by clicking the button below.',
    title_jp: 'ご相談・お見積もりはお気軽にお問い合わせください',
    addressLoc: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3241.7004771153393!2d139.72729791487268!3d35.659750538757926!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188b7769bcd655%3A0x4659072dbf2390e2!2sRoppongi%20Hills%20Cross%20Point%2C%206-ch%C5%8Dme-3-1%20Roppongi%2C%20Minato%20City%2C%20Tokyo%20106-0032!5e0!3m2!1sen!2sjp!4v1645265136246!5m2!1sen!2sjpessLoc',
    contactAddress : '〒108-0074 Tokyo Minato City Takanawa 4-23-6 High Home Takanawa 708',
    contactEmail : 'contact@kakeglobal.com',
    buttonLink: '/contact',
    button_name: 'Find out more',
    button_name_jp: '詳しくはこちら',
};


/* ============================= 
   ABOUT SECTIONS ==  
 ============================= */


/* ============================= 
   CONTACT SECTIONS ==  
 ============================= */
export const CONTACT_PAGE_CONTENT = [
    {
        id: 0,
        header : 'Business Card',
        header_jp : 'ビジネス名刺',
        description : 'We would love to hear more from you. Contact us now.',
        description_jp : 'ご相談・お見積もりはお気軽にお問い合わせください',
        details_1 : '〒108-0074 Tokyo Minato City Takanawa 4-23-6 High Home Takanawa 708',
        details_1_jp : '〒108-0074 東京都港区高輪4丁目23番6号 ハイホーム高輪 708',
        details_2 : 'contact@kakeglobal.com',
        details_2_jp : 'contact@kakeglobal.com',

    },
    {
        id: 1,
        header : 'Working Hour ',
        header_jp : '営業時間 ',
        description : 'Our local business is based in Japan.',
        description_jp : '私たちのローカルビジネスは日本を拠点としております。',
        details_1 : 'Office hour: 9AM ～ 5PM (Excluding Weekends and Public Holidays)',
        details_1_jp : '営業時間 午前9時～午後5時（土・日・祝祭日、年末年始を除く）',
    },
    ]