type AppProps = {
    children: React.ReactNode;
    isLast: boolean;
};

const CardSecImgContainer = ({children, isLast} : AppProps) => {
    return ( 
        
        <div className={`flex items-center sm:w-1/2 md:w-5/12 ${isLast ? 'sm:order-last' : 'sm:order-first'}`}>
            {children}
        </div>
     );
}
 
export default CardSecImgContainer;