import BtnPrimary from "../../basic/buttons/BtnPrimary";
import BorderDash from "../../basic/deco/BorderDash";
import ImgAbout from "../../../assets/home_about.jpg"
import WordBGTrapezium from "../../basic/title/WordBGTrapezium";
import SubTitle from "../../basic/title/SubTitle";
import HeaderTitle from "../../basic/title/HeaderTitle";
import WordBGSquare from "../../basic/title/WordBGSquare";
import ImgContainer from "../../basic/image/ImgContainer";
import CardSecDescContainer from "../../basic/cards/CardSecDescContainer";
import CardSecImgContainer from "../../basic/cards/CardSecImgContainer";

const SecAboutUs = ({isEnglish} : {isEnglish:boolean}) => {
    return ( 
        <section id="About" className='mb-8 px-4 bg-white'>
            
            <SectionContainer isEnglish={isEnglish}/>
            
        </section>
     );
}

const SectionContainer = ({isEnglish} : {isEnglish:boolean}) => {

    return (
        <div className="p-2 md:p-4 xl:p-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl">
            <div className="flex flex-col items-center justify-between w-full sm:flex-row">

                <CardSecDescContainer isAniHoriLeft={true}>
                    <div className="max-w-xl mb-6">
                        <SubTitle>
                            Who are we
                        </SubTitle>
            
                        <HeaderTitle>
                            Kake Global Inc.
                        </HeaderTitle>

                        <BorderDash />

                        {
                            isEnglish?
                            <p className="my-4 text-black text-base md:text-lg sm:text-justify"> 
                                Kake Global Inc. treats 
                                <WordBGSquare>"trust"</WordBGSquare>
                                and 
                                <WordBGSquare>"respect"</WordBGSquare>
                                as our core values and inspiration. <br/>
                                The word "kake" exhibits us as an international trading company started in Japan to 
                                <WordBGTrapezium>bridge</WordBGTrapezium>
                                between local businesses with international audience. Combining both "Kake" word in Japanese and "Global" shows our multinational culture and mindset.
                            </p>
                            :
                            <p className="my-4 text-black text-base md:text-lg sm:text-justify"> 
                                カケ　グローバル 合同会社は、
                                <WordBGSquare>「信頼」</WordBGSquare>
                                と 
                                <WordBGSquare>「尊敬」</WordBGSquare>
                                を私たちのコアバリューとインスピレーションとして扱っています。 
                                <br/>
                                「カケ」という言葉は、地元企業と国際的な聴衆との間を 
                                <WordBGTrapezium>つなぐ</WordBGTrapezium>
                                国際商社としての私たちを表しています。
                                日本語の「カケ」と英語の「グロバル」、そのものの意味で、両方を組み合わせることで、私たちの多国籍文化と国際的な考え方がわかります。
                            </p>
                        }


                        <BtnPrimary btnName={'More'} btnLink={'/about'} isArrow/>
                    </div>
                </CardSecDescContainer>

                <CardSecImgContainer isLast>
                    <ImgContainer ImgSrc={ImgAbout} ImgAlt="about-us-building" isRound={true}/>
                </CardSecImgContainer>
            </div>
        </div>
    )
}

export default SecAboutUs;