const BorderDash = () => {
    return (
        <div className="mb-2">
            <span className="inline-block w-24 h-1 rounded-full bg-primary"></span>
            <span className="inline-block w-6 h-1 ml-1 rounded-full bg-primary"></span>
            <span className="inline-block w-3 h-1 ml-1 rounded-full bg-primary"></span>
            <span className="inline-block w-1 h-1 ml-1 rounded-full bg-primary"></span>
        </div>
    )
}
 
export default BorderDash;