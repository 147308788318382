type AppProps = {
    children: React.ReactNode;
    isAniHoriLeft: boolean;
};

const CardSecDescContainer = ( {children, isAniHoriLeft} : AppProps) => {
    return ( 
        <>
        <div 
            className="
                flex flex-col justify-center 
                sm:w-1/2 md:w-7/12
                pl-1 sm:pl-6 md:pl-10 pr-1 sm:pr-6 md:pr-10
                my-4
            "
        >
            {children}
        </div>
        </>
     );
}
 
export default CardSecDescContainer;