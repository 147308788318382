type AppProps = {
    children: React.ReactNode;
};

const WordBGTrapezium = ({children} : AppProps) => {
    return ( 
        <span className=" mx-2 before:block before:absolute before:-inset-1 before:-skew-y-3 before:bg-primary relative inline-block">
            <span className="relative text-white p-1 font-semibold">
                {children}
            </span>
        </span>
    );
}
 
export default WordBGTrapezium;