import BtnPrimary from "../../components/basic/buttons/BtnPrimary";

const NotFound = () => {
    return ( 
        <div className="bg-white h-[calc(100vh-100px)] flex flex-col justify-center items-center">
            <div className="flex flex-col justify-center items-center w-[80vw] md:w-[60vw] mb-10">
                <h1 className="text-6xl font-bold">404</h1>
                <h3>Page not found</h3>
            </div>
            <div className="w-[80vw] md:w-[60vw] mb-8">
                <h1 className="text-xl font-bold">UH OH! You're lost.</h1>
                <p className="my-4 text-black text-base md:text-lg">
                    The page you are looking for does not exist. How you got here is a mystery. But you can click the button below to go back to the homepage.
                </p>
                
            </div>
            <BtnPrimary btnName={'HOME'} btnLink={'/'} isArrow/>
        </div>
     );
}
 
export default NotFound;