import logo from '../../../assets/kkg_white_vertical.png'; // Tell webpack this JS file uses this image
import { useState, useEffect } from 'react';
import { NAVBAR_LIST } from '../../../data/contents'
import BtnTertier from '../../basic/buttons/BtnTertier';

// <img className="box-border h-16 w-32 p-4" src={logo} alt="Logo" />

type CopyrightType = {
    isOnline: boolean;
};

type LanguageType = {
    isEnglish: boolean;
    LangToggle: any;
};

const Footer = ({isEnglish, LangToggle} : LanguageType ) => {
    // Online state
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    useEffect(() => {
        // Update network status
        const handleStatusChange = () => {
        setIsOnline(navigator.onLine);
        };

        // Listen to the online status
        window.addEventListener('online', handleStatusChange);

        // Listen to the offline status
        window.addEventListener('offline', handleStatusChange);

        // Specify how to clean up after this effect for performance improvment
        return () => {
        window.removeEventListener('online', handleStatusChange);
        window.removeEventListener('offline', handleStatusChange);
        };
    }, [isOnline]);

    return ( 
        <footer className="text-center lg:text-left bg-primary text-white divide-y">
            <div className="mx-6 py-10 text-center md:text-left">
                <div className="grid content-around grid-1 md:grid-cols-2 gap-8">
                    <FooterCompanyBrand />
                    
                    <FooterNavi isEnglish={isEnglish}/>
                </div>
            </div>
            <FooterCopyright isOnline={isOnline}/>
        </footer>

     );
}

const FooterCompanyBrand = () => {
    return (
        <div className="w-[85vw] md:w-[35vw] m-auto">
            <h6 className=" uppercase font-semibold mb-4 
            flex items-center justify-center
            ">
                <img className="box-border" src={logo} alt="Logo" />
            </h6>
            <p className='text-justify'>
                Connecting Japan to the world through commerce and technology.
                Strive long-term value and drive economic growth.
            </p>

        </div>
    );
};

const FooterNavi = ({isEnglish} : {isEnglish: boolean}) => {
    return (
        <div className="flex flex-col justify-start items-center">
            <h6 className="uppercase font-semibold mb-4 underline tracking-wider">
                Site Map
            </h6>

            {NavigationBarList}

            <p className="mt-2 flex items-center justify-center md:justify-start">
            <svg fill="#000000" viewBox="796 796 200 200" 
            className="w-4 mr-4 text-white fill-current" 
            version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" enable-background="new 796 796 200 200" transform="matrix(1, 0, 0, 1, 0, 0)"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M973.166,818.5H818.833c-12.591,0-22.833,10.243-22.833,22.833v109.333c0,12.59,10.243,22.833,22.833,22.833h154.333 c12.59,0,22.834-10.243,22.834-22.833V841.333C996,828.743,985.756,818.5,973.166,818.5z M896,961.5h-77.167 c-5.973,0-10.833-4.859-10.833-10.833V841.333c0-5.974,4.86-10.833,10.833-10.833H896V961.5z M978.58,872.129 c-0.547,9.145-5.668,27.261-20.869,39.845c4.615,1.022,9.629,1.573,14.92,1.573v12c-10.551,0-20.238-1.919-28.469-5.325 c-7.689,3.301-16.969,5.325-28.125,5.325v-12c5.132,0,9.924-0.501,14.366-1.498c-8.412-7.016-13.382-16.311-13.382-26.78h11.999 c0,8.857,5.66,16.517,14.884,21.623c4.641-2.66,8.702-6.112,12.164-10.351c5.628-6.886,8.502-14.521,9.754-20.042h-49.785v-12 h22.297v-11.986h12V864.5h21.055c1.986,0,3.902,0.831,5.258,2.28C977.986,868.199,978.697,870.155,978.58,872.129z"></path> <g> <g> <path d="M839.035,914.262l-4.45,11.258h-15.971l26.355-61.09h15.971l25.746,61.09h-16.583l-4.363-11.258H839.035z M852.475,879.876l-8.902,22.604h17.629L852.475,879.876z"></path> </g> </g> </g> </g></svg>
            <mark className="px-2 text-primary rounded bg-white">
                {isEnglish ? '言語 : ' : 'Language : '}
            </mark>
            <span className='text-PRIMARYWHITE pl-1 text-center'>
                {isEnglish ? 'English' : 'Japanese'}
            </span>
            </p>
            
        </div>
    );
};

// Populate navigation items
const NavigationBarList = NAVBAR_LIST.map((navitem, index) => {
    return (
        <li key={index}
            className="mb-2 list-none"
        >
            <BtnTertier btnName={navitem.title} btnLink={navitem.to} isArrow={true} />
        </li>
    )
});

const FooterCopyright = ( {isOnline} : CopyrightType ) => {
    return (
        <div className="text-center p-2 bg-tertiery ">
            <span>©{new Date().getFullYear()} Copyright Kake Global Inc. </span>
            <span className='inline-flex items-baseline'>
                {isOnline ? 
                <div className="w-3 h-3 bg-green-400 rounded-full animate-pulse"></div>
                : 
                <div className="w-3 h-3 bg-red-400 rounded-b-full"></div>
                }
            </span>
            <FooterFarhan />
        </div>
    );
};

const FooterFarhan = () => {
    return (
        <div className="text-center p-0 m-0">
            <span
                className='text-sm text-primary hover:text-gray-400 animate-puff-out-center hover:animate-puff-in-center p-0 m-0'
            >
                By F.Farhan
            </span>
        </div>
    );
}


export default Footer;